import Banner from "../components/Banner";
import React from "react";
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import {PortableText} from "@portabletext/react";

export default function Contact({data}) {
    return (
        <Layout title={"Contact"}>
            <Banner heightSmall={true}
                    backgroundImage={"https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-1.2.1&ixid=M"}>
                <div className={"banner-title"}>
                    <span style={{fontSize: "3rem", fontWeight: "bold"}}>Contact</span>
                </div>
            </Banner>
            <article className="section">
                <h1 className="title" style={{paddingBottom: '20px'}}>
                    Our Locations
                </h1>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gap: '20px',
                    maxHeight: 'min-content'
                }}>
                    {
                        data.settings.regions.map(c => {
                            return <ContactCard contact={c}></ContactCard>
                        })
                    }
                    <ContactCard contact={data.settings.primaryContact} isCurrent></ContactCard>
                </div>
            </article>
        </Layout>
    )
}

function ContactCard({contact, isCurrent}) {
    let c = contact
    return <div className="card" style={{display: 'flex', flexDirection: 'column'}}>
        <div className="stack-padded" style={{flexGrow: 1}}>
            <div className="title">{c.country ?? ""}</div>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <img src="/media/call_FILL0_wght400_GRAD0_opsz48.svg" alt="phone"
                     style={{height: '1.5rem'}}/>
                <div>
                    <div>{c.phone ? "TEL: " + c.phone : ""}</div>
                    <div>{c.fax ? "FAX: " + c.fax : ""}</div>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <img src="/media/mail_FILL0_wght400_GRAD0_opsz48.svg" alt="email"
                     style={{height: '1.5rem'}}/>
                <a href={"mailto:" + c.email}>{c.email ?? ""}</a>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <img src="/media/domain_FILL0_wght400_GRAD0_opsz48.svg" alt="address"
                     style={{height: '1.5rem'}}/>
                {c._rawAddress ? <PortableText value={c._rawAddress}/> : <div/>}
            </div>
        </div>
        <a className="link-btn" href={isCurrent ? "mailto:"+c.email : c.link}>{isCurrent ? "Contact" : "Visit"}</a>


    </div>
}

export const query = graphql`
{
  settings: sanitySiteSettings(_id: {eq: "siteSettings"}) {
    _id
    primaryContact {
      country
      phone
      fax
      email
      link
      _rawAddress
    }
    regions {
      country
      phone
      fax
      email
      link
      _rawAddress
    }
  }
}
`
